import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";

import FormQuestions from "../../components/Form/Questions.jsx";
import QuizAdmin from "../../components/Quiz/Admin.jsx";
import Quiz from "../../components/Quiz/Quiz.jsx";
import PageContent from "../../components/Lawsuit/PageContent.jsx";
import LawsuitScheduleList from "../../components/LawsuitSchedule/List.jsx";
import VideoList from "../../components/Videos/List.jsx";
import CertificateList from "../../components/Certificate/List.jsx";

import BurguerMenu from "../../components/BurguerMenu/BurguerMenu.jsx";

import FormContentPage from "../../components/Form/FormContentPage.jsx";
import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
import TrailPage from "../../components/CoursePage/CoursePage.jsx";
import SliderLms from "../../components/Slider/Slider.jsx";
import LmsPage from "../../components/Lms/LmsPage.jsx";

import Forum from "../../components/Forum/Forum.jsx";
import FooterZ from "../../components/Footer/FooterZ.jsx";

import ReactHtmlParser from "react-html-parser";

import LawsuitAssets from "../../components/Lawsuit/Assets.jsx";
import ChatAndQuiz from "../../components/Lawsuit/ChatAndQuiz.jsx";
import { Chat } from "../../components/Chat/Chat.jsx";

import { Zoom } from "../../components/Zoom/Zoom.jsx";

import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import * as Scroll from "react-scroll";
import Button from "../../components/CustomButton/CustomButton.jsx";

import aulaaovivo from "../../assets/icons/aulaaovivo.svg";
import aulas from "../../assets/icons/aulas.svg";
import avaliacao from "../../assets/icons/avaliacao.svg";
import certificado from "../../assets/icons/certificado.svg";
import comentarios from "../../assets/icons/comentarios.svg";
import materiais from "../../assets/icons/materiais.svg";
import paginainicial from "../../assets/icons/paginainicial.svg";
import perfil from "../../assets/icons/perfil.svg";
import replay from "../../assets/icons/replay.svg";
import programacao from "../../assets/icons/programacao.svg";
import professores from "../../assets/icons/professores.svg";
import sair from "../../assets/icons/sair.svg";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import { HttpLink } from "apollo-link-http";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; //

import { Navbar, Nav, NavItem, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import bannerDefault from "../../assets/img/banner-default-event.png";
import logo from "../../assets/img/Zenite_Branco.svg";
import imagine from "../../assets/img/sidebar-4.jpg";

const restLink_form = new HttpLink({
  credentials: "include",
  uri: "/api/v1/forms/",
});
const form_client = new ApolloClient({
  link: restLink_form,
  cache: new InMemoryCache(),
});

const restLink_account = new HttpLink({
  credentials: "include",
  uri: "/api/v1/account/",
});
const account_client = new ApolloClient({
  link: restLink_account,
  cache: new InMemoryCache(),
});

const restLink_analytics = new HttpLink({
  credentials: "include",
  uri: "/api/v1/analytics/",
});
const analytics_client = new ApolloClient({
  link: restLink_analytics,
  cache: new InMemoryCache(),
});

// Account Queries

const CONFIRM_USER = gql`
  mutation confirmUserLawsuit($lawsuit_id: Int!) {
    confirmUserLawsuit(lawsuit_id: $lawsuit_id) {
      id
    }
  }
`;

const GET_CLICKMEETING = gql`
  query ClickmeetingConferenceByLawsuit($lawsuit_id: Int!) {
    ClickmeetingConferenceByLawsuit(lawsuit_id: $lawsuit_id) {
      id
      room_pin
      user_token
      url
    }
  }
`;
const GET_USERHASLAWSUIT = gql`
  query user_has_lawsuit($lawsuit_id: Int!) {
    user_has_lawsuit(lawsuit_id: $lawsuit_id) {
      id
      is_absent
      type
      user {
        id
        name
        mail
      }
      lawsuit {
        id
        title
        presenter
        type
        subtitle
        chat_id
        description
        certificate_description
        isFirst
        isInstalled
        isChatActive
        isLive
        isSchedule
        accreditation_start_format_br_text
        accreditation_end_format_br_text
        accreditation_started
        accreditation_ended
        banner
        isVimeo
        is_quiz
        embed
        embed2
        embedIsZoom
        embed2IsZoom
        trail_id
        text_date
        isReplay
        isMaterials
        isEvaluation
        isVoting
        isLms
        isForum
      }
    }
  }
`;

// Form Queries
const GET_FORMS = gql`
  query getForms(
    $lawsuit_id: Int!
    $type: String!
    $search: String!
    $limit: Int!
    $page: Int!
    $order: String!
    $orderby: String!
  ) {
    forms_collections(
      lawsuit_id: $lawsuit_id
      type: $type
      search: $search
      limit: $limit
      page: $page
      order: $order
      orderby: $orderby
    ) {
      forms {
        id
        title
        description
        lawsuit_id
        datetimeStartFormat
        datetimeEndFormat
      }
    }
  }
`;

// Analytics Queries
const ANALYTICS_INSERT_CHAT_HAS_USER = gql`
  mutation createChatHasUser($chat_id: Int!) {
    createChatHasUser(input: { chat_id: $chat_id }) {
      id
    }
  }
`;

// #### Queryes apenas para Professores ####

// Form Queries

const GET_FORMS_TO_TEACHER = gql`
  query forms_collections($lawsuit_id: Int!) {
    forms_collections(list_all: 1, lawsuit_id: $lawsuit_id, get_inactive: 1) {
      forms {
        id
        title
        questions {
          id
          title
          isActive
        }
      }
    }
  }
`;
const QUESTION_TOGGLE_ACTIVE = gql`
  mutation questionToggleActive($question_id: Int!, $isActive: Int!) {
    questionToggleActive(question_id: $question_id, isActive: $isActive) {
      id
    }
  }
`;

const GET_LAWSUIT = gql`
  query getLawsuit($lawsuit_id: Int!) {
    lawsuit(id: $lawsuit_id) {
      trail_id
    }
  }
`;
// End > Queryes apenas para Professores

var $chat_id = false;
var $render_start = true;
var $show_room = false;

var scroll_to = Scroll.animateScroll;

class Accreditation extends Component {
  form_id;
  form_edit;
  loggedQuestionInterval;

  constructor() {
    super();
    this.state = {
      lmsShowSidebar_toggle: false,
      lawsuit_id: 0,
      user_has_lawsuit: false,
      forms_collections: [],
      enableQuiz: false,
      view: "all",
      viewGroup: "live",
      trail_id: 0,
      active: false,
      itemActive: [],
      trailItemActive: [],
      completedCourse: [],
      player: false,
      endQuiz: false,
      night_mode: false,
      show_embed_key: 1,
    };

    setInterval(this.setChatHasUser, 180000);
  }

  setChatHasUser() {
    //console.log("------ START INSERT VIEW ---------------");
    if ($chat_id && $show_room) {
      //console.log("------ INSERT VIEW ---------------");
      analytics_client.mutate({
        variables: { chat_id: $chat_id },
        mutation: ANALYTICS_INSERT_CHAT_HAS_USER,
      });
    }
  }

  setLms = async (trailItemActive, itemActive) => {
    this.setState({
      trailItemActive,
      itemActive,
    });
  };

  handler = async (player) => {
    //console.log("---------- Acreditation handler ---------------");
    //console.log(player);
    this.setState({
      player,
    });
  };

  completedCourse = async (completedCourse) => {
    this.setState({
      completedCourse: completedCourse,
    });
  };

  // setItemActive = async (itemActive) => {
  //   this.setState({
  //     itemActive: itemActive
  //   });
  // }

  toggleClass = async () => {
    this.setState({
      active: !this.state.active,
    });
    //console.log(this.state.active);
  };

  setEnableQuiz = async (enableQuiz) => {
    this.setState({
      enableQuiz,
      endQuiz: false,
    });
  };

  setEndQuiz = async () => {
    this.setState({
      endQuiz: true,
    });
  };

  nightModeToggle = async () => {
    this.setState({
      night_mode: !this.state.night_mode,
    });
  };

  getVars = async () => {
    if (this.props.match.params.lawsuit_id) {
      this.lawsuit_id = parseInt(this.props.match.params.lawsuit_id);

      const user_has_lawsuit = (
        await account_client.query({
          query: GET_USERHASLAWSUIT,
          variables: { lawsuit_id: this.lawsuit_id },
          fetchPolicy: "no-cache",
        })
      ).data.user_has_lawsuit;

      // console.log(user_has_lawsuit);
      // Form

      const forms_collections = (
        await form_client.query({
          variables: {
            lawsuit_id: this.lawsuit_id,
            type: "form",
            search: "",
            limit: 1,
            page: 1,
            order: "DESC",
            orderby: "id",
          },
          query: GET_FORMS,
          fetchPolicy: "no-cache",
        })
      ).data.forms_collections.forms;

      this.setState({ user_has_lawsuit, forms_collections });
    } // if(this.props.match.params.lawsuit_id) {

    setTimeout(this.getVars, 120000);
  };

  changeView = async (view, viewGroup) => {
    var lmsShowSidebar_toggle = !this.state.lmsShowSidebar_toggle;

    this.setState({
      view,
      viewGroup,
      lmsShowSidebar_toggle,
    });

    // console.log(scroll_to.scrollToTop);
    scroll_to.scrollToTop({
      to: "teacher",
      duration: 1500,
      delay: 100,
    });
  };

  componentDidMount() {
    this.getVars();
    //this.getVarsInterval = setInterval(this.getVars, 60000);
  }

  componentWillUnmount() {
    //clearInterval(this.getVarsInterval);
  }

  activeToggle(item) {
    // Só utiliza a var item.approved, quando o State não está definido, pois o item.approved é fixo
    var approved_var =
      this.state[item.id + "_isActive"] !== undefined
        ? this.state[item.id + "_isActive"]
        : item.isActive;
    var approved = approved_var ? 0 : 1;
    form_client.mutate({
      mutation: QUESTION_TOGGLE_ACTIVE,
      variables: {
        question_id: item.id,
        isActive: approved,
      },
    });
    // Alterar o state
    this.setState((state) => ({
      ...state,
      [item.id + "_isActive"]: approved,
    }));
  }

  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + imagine + ")",
    };

    var bannerEvent = bannerDefault;
    var bannerActive = false;
    if (
      this.state.user_has_lawsuit &&
      this.state.user_has_lawsuit.lawsuit &&
      this.state.user_has_lawsuit.lawsuit.banner
    ) {
      bannerEvent =
        "/account/assets/" + this.state.user_has_lawsuit.lawsuit.banner;
      bannerActive = true;
    }

    var settings_banner = {
      autoplaySpeed: 5000,
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    if (
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.show_room &&
      this.props.match.params.show_room == 1
    ) {
      $show_room = true;
    } else {
      $show_room = false;
    }

    var content_page = <h1>Aguarde....</h1>;

    if (this.state.user_has_lawsuit.id !== undefined) {
      if (
        this.state.user_has_lawsuit.is_absent !== 1 &&
        this.props.match.params.show_room &&
        this.props.match.params.show_room == 1
      ) {
        $chat_id = parseInt(this.state.user_has_lawsuit.lawsuit.chat_id);
        if ($render_start) {
          $render_start = false;
          this.setChatHasUser();
        }

        if (this.state.user_has_lawsuit.lawsuit.isVimeo === 1) {
          var cols_player = 12;
          var cols_chat = 0;
          if (this.state.enableQuiz && !this.state.endQuiz) {
            cols_player = 6;
            cols_chat = 6;
          } else if (this.state.user_has_lawsuit.lawsuit.isChatActive) {
            if (this.state.user_has_lawsuit.lawsuit.embed == "") {
              cols_player = 12;
              cols_chat = 12;
            } else {
              cols_player = 9;
              cols_chat = 3;
            }
          }

          return (
            <div
              className={
                (this.state.enableQuiz ? "page_quiz container" : "container1") +
                (this.state.night_mode ? " night_mode" : "")
              }
            >
              <Row>
                <div className="header-chat row">
                  <Col md={7}>
                    <a
                      href="/#/home"
                      className="btn btn-fill btn-info btn-zenite-text btn-chat"
                    >
                      Sair
                    </a>
                  </Col>
                </div>

                <Col md={cols_player} className="">
                  {this.state.show_embed_key === 1 && (
                    <React.Fragment>
                      {this.state.user_has_lawsuit.lawsuit.embedIsZoom === 1 ? (
                        <Zoom
                          meetingNumber={
                            this.state.user_has_lawsuit.lawsuit.embed
                          }
                          user_name={this.state.user_has_lawsuit.user.name}
                          user_email={this.state.user_has_lawsuit.user.mail}
                        />
                      ) : (
                        <React.Fragment>
                          {this.state.user_has_lawsuit.lawsuit.embed !== "" && (
                            <React.Fragment>
                              {this.state.user_has_lawsuit.lawsuit.embed.indexOf(
                                "https"
                              ) === 0 ? (
                                <a
                                  href={
                                    this.state.user_has_lawsuit.lawsuit.embed
                                  }
                                  className="btn btn-primary btn-fill btnJoinZoom"
                                  target="_blank"
                                >
                                  ENTRAR NA SALA
                                </a>
                              ) : (
                                <div className="embed-responsive embed-responsive-16by9 card shadow-sm">
                                  {ReactHtmlParser(
                                    this.state.user_has_lawsuit.lawsuit.embed
                                  )}
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  {this.state.show_embed_key === 2 && (
                    <React.Fragment>
                      {this.state.user_has_lawsuit.lawsuit.embed2IsZoom ===
                      1 ? (
                        <Zoom
                          meetingNumber={
                            this.state.user_has_lawsuit.lawsuit.embed2
                          }
                          user_name={this.state.user_has_lawsuit.user.name}
                          user_email={this.state.user_has_lawsuit.user.mail}
                        />
                      ) : (
                        <div className="embed-responsive embed-responsive-16by9 card shadow-sm">
                          {ReactHtmlParser(
                            this.state.user_has_lawsuit.lawsuit.embed2
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  )}

                  {this.state.user_has_lawsuit.lawsuit.embed2 !== undefined &&
                    this.state.user_has_lawsuit.lawsuit.embed2 !== "" &&
                    this.state.user_has_lawsuit.lawsuit.embed2 !== null && (
                      <div>
                        {this.state.show_embed_key === 1 && (
                          <Button
                            bsStyle="info"
                            fill
                            onClick={() => this.setState({ show_embed_key: 2 })}
                          >
                            Não está conseguindo assistir? Clique aqui.
                          </Button>
                        )}
                        {this.state.show_embed_key === 2 && (
                          <Button
                            bsStyle="info"
                            fill
                            onClick={() => this.setState({ show_embed_key: 1 })}
                          >
                            Voltar ao Player principal
                          </Button>
                        )}
                      </div>
                    )}
                  <div className="both"></div>
                  <div className="bar_player_bottom">
                    <Button
                      className="btn btn-primary btn-fill btn_night_mode"
                      onClick={() => this.nightModeToggle()}
                    >
                      Modo Noturno
                      <i className="pe-7s-moon"></i>
                    </Button>
                  </div>
                </Col>
                {this.state.user_has_lawsuit.lawsuit.isChatActive && (
                  <Col
                    md={cols_chat}
                    className="no-padding-left no-padding-top no-margin-top"
                  >
                    <div>
                      {(!this.state.enableQuiz || this.state.endQuiz) && (
                        <div>
                          {/* <Link className="link_scroll" to="arquivos_div" spy={true} smooth={true} offset={50} duration={500} onSetActive={this.handleSetActive}>
                            MATERIAIS
                          </Link> */}
                        </div>
                      )}

                      {this.state.user_has_lawsuit &&
                        this.state.user_has_lawsuit.lawsuit && (
                          <ChatAndQuiz
                            lawsuit={this.state.user_has_lawsuit.lawsuit}
                          />
                        )}

                      {/**
                         
                          <Chat chat_id={this.state.user_has_lawsuit.lawsuit.chat_id} enable={!this.state.enableQuiz || this.state.endQuiz} />
                          {
                            this.state.user_has_lawsuit.lawsuit.is_quiz === 1
                            &&
                            <Quiz setEnableQuiz={this.setEnableQuiz} setEndQuiz={this.setEndQuiz} lawsuit={this.state.user_has_lawsuit.lawsuit} />
                          }
                        */}
                    </div>
                  </Col>
                )}

                <Col md={9}>
                  <div className="assets_live">
                    <LawsuitAssets lawsuit_id={this.lawsuit_id} />
                  </div>
                </Col>
              </Row>

              {this.state.user_has_lawsuit.lawsuit.is_quiz === 1 &&
                this.state.user_has_lawsuit.type === "1" && (
                  <div>
                    <QuizAdmin lawsuit={this.state.user_has_lawsuit.lawsuit} />
                  </div>
                )}

              {this.state.user_has_lawsuit.type !== "1" &&
                this.state.forms_collections.length > 0 && (
                  <div>
                    {this.state.forms_collections.map((form) => (
                      <div key={form.id}>
                        <FormQuestions
                          form={form}
                          title={
                            this.state.user_has_lawsuit.lawsuit.isVoting
                              ? "Votação"
                              : "Avaliação"
                          }
                        />
                      </div>
                    ))}
                  </div>
                )}
              {/*
                // Forms to Teacher
                this.state.user_has_lawsuit.type === "1" &&
                <div>
                    <h2 className="label-blue">Gestão das perguntas:</h2>
                    <Query
                      client={form_client}
                      query={GET_FORMS_TO_TEACHER}
                      variables={{
                        lawsuit_id: parseInt(this.lawsuit_id)
                      }}
                      fetchPolicy='no-cache'
                      >
                      {({ loading, error, data }) => {
                        if (loading) return <div>Carregando...</div>;
                        if (error) return <div>Erro ao buscar as perguntas</div>;
  
                          if(!data.forms_collections.forms.length) {
                            return <div className="text-center"><strong>(Nenhuma pergunta cadastrada)</strong></div>;
                          }
                          return data.forms_collections.forms.map((form) => (
                            <div key={form.id}>
                              {form.questions.map((question) => (
                                  <Row key={question.id}>
                                    <Col md={3}>
                                      <Button bsStyle={(this.state[question.id+"_isActive"] === undefined && question.isActive === 1) || this.state[question.id+"_isActive"] === 1? "info" : "danger"} fill onClick={() => this.activeToggle(question)}>
                                        {(this.state[question.id+"_isActive"] === undefined && question.isActive === 1) || this.state[question.id+"_isActive"] === 1? "Ativo" : "Inativo"}
                                      </Button>
                                    </Col>
                                    <Col md={9}>
                                      {question.title}
                                    </Col>
                                  </Row>
                              ))}
                            </div>
                          ));
                        }}
                      </Query>
                  </div>
                */}
            </div>
          );
        } else {
          // Se a AGC estiver instala
          if (this.state.user_has_lawsuit.lawsuit.isInstalled) {
            content_page = (
              <Query
                client={account_client}
                query={GET_CLICKMEETING}
                variables={{
                  lawsuit_id: parseInt(this.props.match.params.lawsuit_id),
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <div>Carregando...</div>;
                  if (error)
                    return (
                      <div>
                        Erro ao buscar a sala, atualize a página ou tente mais
                        tarde
                      </div>
                    );

                  var clickmeeting = data.ClickmeetingConferenceByLawsuit;

                  return (
                    <div>
                      {clickmeeting &&
                        clickmeeting.room_pin &&
                        clickmeeting.user_token && (
                          <div>
                            <Row>
                              <Col
                                md={
                                  this.state.user_has_lawsuit.lawsuit
                                    .isChatActive
                                    ? 9
                                    : 12
                                }
                                className="no-padding-right no-padding-top no-margin-top"
                              >
                                <div className="header-chat row">
                                  <a
                                    href="/#/home"
                                    className="btn btn-fill btn-info btn-zenite-text btn-chat"
                                  >
                                    Sair
                                  </a>
                                </div>
                                <iframe
                                  src={
                                    clickmeeting.url +
                                    "/" +
                                    clickmeeting.room_pin +
                                    "?popup=off&lang=en&xlang=en&l=" +
                                    clickmeeting.user_token
                                  }
                                  width="1024px"
                                  height="850px"
                                  id="myId"
                                  className={
                                    "clickmeeting_embed " +
                                    (this.state.question_current
                                      ? "active"
                                      : "")
                                  }
                                  position="relative"
                                  allow="microphone *; camera *; fullscreen *; autoplay *; display-capture *"
                                  allowFullScreen={true}
                                  webkitallowfullscreen="true"
                                  mozallowfullscreen="true"
                                  msallowfullscreen="true"
                                  allowusermedia="true"
                                ></iframe>
                              </Col>
                              {this.state.user_has_lawsuit.lawsuit
                                .isChatActive && (
                                <Col
                                  md={3}
                                  className="no-padding-left no-padding-top no-margin-top"
                                >
                                  <div>
                                    <Link
                                      className="link_scroll"
                                      to="arquivos_div"
                                      spy={true}
                                      smooth={true}
                                      offset={50}
                                      duration={500}
                                      onSetActive={this.handleSetActive}
                                    >
                                      MATERIAIS
                                    </Link>
                                  </div>
                                  <div>
                                    <Chat
                                      chat_id={
                                        this.state.user_has_lawsuit.lawsuit
                                          .chat_id
                                      }
                                    />
                                  </div>
                                </Col>
                              )}
                            </Row>
                            <div className="assets_live">
                              <LawsuitAssets lawsuit_id={this.lawsuit_id} />
                            </div>
                          </div>
                        )}

                      {this.state.user_has_lawsuit.type !== "1" &&
                        this.state.forms_collections.length > 0 && (
                          <div>
                            {this.state.forms_collections.map((form) => (
                              <div key={form.id}>
                                <FormQuestions
                                  form={form}
                                  title={
                                    this.state.user_has_lawsuit.lawsuit.isVoting
                                      ? "Votação"
                                      : "Avaliação"
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        )}

                      {// Forms to Teacher
                      0 && this.state.user_has_lawsuit.type === "1" && (
                        <div>
                          <h2 className="label-blue">Gestão das perguntas:</h2>
                          <Query
                            client={form_client}
                            query={GET_FORMS_TO_TEACHER}
                            variables={{
                              lawsuit_id: parseInt(this.lawsuit_id),
                            }}
                            fetchPolicy="no-cache"
                          >
                            {({ loading, error, data }) => {
                              if (loading) return <div>Carregando...</div>;
                              if (error)
                                return <div>Erro ao buscar as perguntas</div>;

                              if (!data.forms_collections.forms.length) {
                                return (
                                  <div className="text-center">
                                    <strong>
                                      (Nenhuma pergunta cadastrada)
                                    </strong>
                                  </div>
                                );
                              }
                              return data.forms_collections.forms.map(
                                (form) => (
                                  <div key={form.id}>
                                    {form.questions.map((question) => (
                                      <Row key={question.id}>
                                        <Col md={3}>
                                          <Button
                                            bsStyle={
                                              (this.state[
                                                question.id + "_isActive"
                                              ] === undefined &&
                                                question.isActive === 1) ||
                                              this.state[
                                                question.id + "_isActive"
                                              ] === 1
                                                ? "info"
                                                : "danger"
                                            }
                                            fill
                                            onClick={() =>
                                              this.activeToggle(question)
                                            }
                                          >
                                            {(this.state[
                                              question.id + "_isActive"
                                            ] === undefined &&
                                              question.isActive === 1) ||
                                            this.state[
                                              question.id + "_isActive"
                                            ] === 1
                                              ? "Ativo"
                                              : "Inativo"}
                                          </Button>
                                        </Col>
                                        <Col md={9}>{question.title}</Col>
                                      </Row>
                                    ))}
                                  </div>
                                )
                              );
                            }}
                          </Query>
                        </div>
                      )}
                    </div>
                  );
                }}
              </Query>
            );
            /*<div>
              <NavLink
                  to="/forms"
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                Acessar Votação
              </NavLink>
            </div>;*/
          } else {
            content_page = "";
          }
        }
      } else if (
        this.state.user_has_lawsuit.lawsuit.accreditation_ended === "true"
      ) {
        content_page = (
          <div className="min-h-60">
            <PageContent
              bannerEvent={bannerEvent}
              lawsuit={this.state.user_has_lawsuit.lawsuit}
              showAbout={this.state.view === "all"}
              bannerActive={bannerActive}
              statusPage=""
              /*
            statusPage={
              <Grid fluid>
                Aula ao vivo encerrada {this.state.user_has_lawsuit.lawsuit.accreditation_end_format_br_text}
              </Grid>
            }
            */
            />

            {((this.state.user_has_lawsuit && this.state.view === "allx") ||
              this.state.view === "teacher" ||
              this.state.view === "schedule") && (
              <LawsuitScheduleList
                isActive={this.state.user_has_lawsuit.lawsuit.isActive}
                viewState={this.state.view}
                isSchedule={this.state.user_has_lawsuit.lawsuit.isSchedule}
                lawsuit_id={this.lawsuit_id}
              />
            )}

            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isMaterials === 1 &&
              (this.state.view === "allx" || this.state.view === "assets") && (
                <LawsuitAssets lawsuit_id={this.lawsuit_id} />
              )}
            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isForum === 1 &&
              (this.state.view === "allx" || this.state.view === "forum") && (
                <Forum
                  module_name="Lawsuit"
                  external_parent_id={this.state.user_has_lawsuit.lawsuit.id}
                />
              )}

            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isReplay === 1 &&
              (this.state.view === "allx" || this.state.view === "video") && (
                <VideoList lawsuit_id={this.lawsuit_id} />
              )}

            {this.state.user_has_lawsuit.lawsuit.isLive !== 1 &&
              (this.state.view === "allx" || this.state.view === "islive") && (
                <React.Fragment>
                  <div class="container">
                    <div class="col-md-12">
                      <h3>
                        {this.state.user_has_lawsuit.lawsuit.type === "play"
                          ? "Chat"
                          : "Aula ao vivo"}
                      </h3>
                      <p className="z-p">
                        O botão para ingressar na sala irá aparecer no topo
                        dessa página{" "}
                        {
                          this.state.user_has_lawsuit.lawsuit
                            .accreditation_start_format_br_text
                        }
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              )}

            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isEvaluation === 1 &&
              (this.state.view === "allx" || this.state.view === "form") && (
                <FormContentPage lawsuit_id={this.lawsuit_id} />
              )}

            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isVoting === 1 &&
              (this.state.view === "allx" || this.state.view === "voting") && (
                <FormContentPage lawsuit_id={this.lawsuit_id} title="Votação" />
              )}

            {(this.state.view === "allx" ||
              this.state.view === "certificate") && (
              <CertificateList lawsuit={this.state.user_has_lawsuit.lawsuit} />
            )}
          </div>
        );
      } else if (
        this.state.user_has_lawsuit.lawsuit.accreditation_started === "false" &&
        this.state.user_has_lawsuit.type !== "1"
      ) {
        content_page = (
          <div className="min-h-60">
            <PageContent
              bannerEvent={bannerEvent}
              lawsuit={this.state.user_has_lawsuit.lawsuit}
              showAbout={this.state.view === "all"}
              bannerActive={bannerActive}
              statusPage=""
              /*
            statusPage={
              <Grid fluid>
                A aula ao vivo terá início {this.state.user_has_lawsuit.lawsuit.accreditation_start_format_br_text}
              </Grid>
            }
            */
            />

            {((this.state.user_has_lawsuit && this.state.view === "allx") ||
              this.state.view === "teacher" ||
              this.state.view === "schedule") && (
              <LawsuitScheduleList
                isActive={this.state.user_has_lawsuit.lawsuit.isActive}
                viewState={this.state.view}
                isSchedule={this.state.user_has_lawsuit.lawsuit.isSchedule}
                lawsuit_id={this.lawsuit_id}
              />
            )}

            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isMaterials === 1 &&
              (this.state.view === "allx" || this.state.view === "assets") && (
                <LawsuitAssets lawsuit_id={this.lawsuit_id} />
              )}
            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isForum === 1 &&
              (this.state.view === "allx" || this.state.view === "forum") && (
                <Forum
                  module_name="Lawsuit"
                  external_parent_id={this.state.user_has_lawsuit.lawsuit.id}
                />
              )}

            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isReplay === 1 &&
              (this.state.view === "allx" || this.state.view === "video") && (
                <VideoList lawsuit_id={this.lawsuit_id} />
              )}

            {this.state.user_has_lawsuit.lawsuit.isLive !== 1 &&
              (this.state.view === "allx" || this.state.view === "islive") && (
                <React.Fragment>
                  <div class="container">
                    <div class="col-md-12">
                      <h3>
                        {this.state.user_has_lawsuit.lawsuit.type === "play"
                          ? "Chat"
                          : "Aula ao vivo"}
                      </h3>
                      <p className="z-p">
                        O botão para ingressar na sala irá aparecer no topo
                        dessa página{" "}
                        {
                          this.state.user_has_lawsuit.lawsuit
                            .accreditation_start_format_br_text
                        }
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              )}

            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isEvaluation === 1 &&
              (this.state.view === "allx" || this.state.view === "form") && (
                <FormContentPage lawsuit_id={this.lawsuit_id} />
              )}
            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isVoting === 1 &&
              (this.state.view === "allx" || this.state.view === "voting") && (
                <FormContentPage lawsuit_id={this.lawsuit_id} title="Votação" />
              )}

            {(this.state.view === "allx" ||
              this.state.view === "certificate") && (
              <CertificateList lawsuit={this.state.user_has_lawsuit.lawsuit} />
            )}
          </div>
        );
      } else {
        content_page = (
          <div className="min-h-60">
            {this.state.user_has_lawsuit.lawsuit && (
              <PageContent
                bannerEvent={bannerEvent}
                bannerActive={bannerActive}
                lawsuit={this.state.user_has_lawsuit.lawsuit}
                showAbout={this.state.view === "all"}
                statusPage={
                  <Button
                    className="btn-zenite-live"
                    onClick={() => {
                      confirmAlert({
                        title: "",
                        message: "Tem certeza que deseja entrar na sala?",
                        buttons: [
                          {
                            label: "Sim",
                            onClick: () => {
                              // Delete item
                              account_client
                                .mutate({
                                  mutation: CONFIRM_USER,
                                  variables: { lawsuit_id: this.lawsuit_id },
                                })
                                .then((result) => {
                                  window.location.replace(
                                    "/#/meeting/" +
                                      this.props.match.params.lawsuit_id +
                                      "/1"
                                  );
                                  window.location.reload();
                                });
                            },
                          },
                          {
                            label: "Não",
                          },
                        ],
                      });
                    }}
                  >
                    <div>
                      <strong>
                        {this.state.user_has_lawsuit.lawsuit.type === "play"
                          ? "ACESSAR CHAT"
                          : "ASSISTIR AO VIVO"}
                      </strong>
                    </div>
                  </Button>
                }
              />
            )}

            {((this.state.user_has_lawsuit && this.state.view === "allx") ||
              this.state.view === "teacher" ||
              this.state.view === "schedule") && (
              <LawsuitScheduleList
                isActive={this.state.user_has_lawsuit.lawsuit.isActive}
                viewState={this.state.view}
                isSchedule={this.state.user_has_lawsuit.lawsuit.isSchedule}
                lawsuit_id={this.lawsuit_id}
              />
            )}
            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isMaterials === 1 &&
              (this.state.view === "allx" || this.state.view === "assets") && (
                <LawsuitAssets lawsuit_id={this.lawsuit_id} />
              )}
            {this.state.user_has_lawsuit.lawsuit.isLive !== 1 &&
              (this.state.view === "allx" || this.state.view === "islive") && (
                <React.Fragment>
                  <div class="container">
                    <div class="col-md-12">
                      <h3>
                        {this.state.user_has_lawsuit.lawsuit.type === "play"
                          ? "Chat"
                          : "Aula ao vivo"}
                      </h3>
                      <p className="z-p">
                        O botão para ingressar na sala irá aparecer no topo
                        dessa página{" "}
                        {
                          this.state.user_has_lawsuit.lawsuit
                            .accreditation_start_format_br_text
                        }
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              )}

            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isForum === 1 &&
              (this.state.view === "allx" || this.state.view === "forum") && (
                <Forum
                  module_name="Lawsuit"
                  external_parent_id={this.state.user_has_lawsuit.lawsuit.id}
                />
              )}

            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isReplay === 1 &&
              (this.state.view === "allx" || this.state.view === "video") && (
                <VideoList lawsuit_id={this.lawsuit_id} />
              )}

            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isEvaluation === 1 &&
              (this.state.view === "allx" || this.state.view === "form") && (
                <FormContentPage lawsuit_id={this.lawsuit_id} />
              )}
            {this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.isVoting === 1 &&
              (this.state.view === "allx" || this.state.view === "voting" || (this.state.user_has_lawsuit.lawsuit.type === "play" && this.state.view === "all")) && (
                <FormContentPage lawsuit_id={this.lawsuit_id} title="Votação" />
              )}

            {(this.state.view === "allx" ||
              this.state.view === "certificate") && (
              <CertificateList lawsuit={this.state.user_has_lawsuit.lawsuit} />
            )}
          </div>
        );
      }
    }

    return (
      <div
        className={
          "container-acreditation " +
          (this.state.user_has_lawsuit && this.state.user_has_lawsuit.lawsuit
            ? this.state.user_has_lawsuit.lawsuit.type
            : "")
        }
      >
        {!this.props.match.params.show_room && (
          <div>
            <BurguerMenu setActive={this.toggleClass} />
            <div
              id="sidebar"
              className={
                (this.state.active ? "active" : "") +
                " sidebar sidebar-acreditation"
              }
              data-color="black"
            >
              {/* <div className="sidebar-background" style={sidebarBackground} /> */}
              <div className="logo">
                <img src={logo} />
                <Query
                  client={account_client}
                  query={gql`
                    {
                      get_authenticated_user {
                        id
                        first_name
                        profile {
                          is_admin
                        }
                      }
                    }
                  `}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <div></div>;
                    if (error) return <div></div>;

                    var user = data.get_authenticated_user;

                    return (
                      <div className="text-header-zenite my-3">
                        Bem vindo, <strong>{user.first_name}</strong>
                      </div>
                    );
                  }}
                </Query>
              </div>
              <div className="sidebar-wrapper">
                <ul className="nav">
                  {/* <li className="active">
                    <a className="nav-link active" aria-current="page" href="#/home">
                    
                    
                      <p><img width={24} height={24} className="icons-acreditation" src={paginainicial} />  Início</p>
                    </a>
                  </li> */}
                  <li>
                    <a
                      onClick={() => this.changeView("all", "live")}
                      className="link"
                    >
                      <p>
                        <img
                          width={24}
                          height={24}
                          className="icons-acreditation"
                          src={paginainicial}
                        />
                        Página Inicial
                      </p>
                    </a>
                  </li>
                  {this.state.user_has_lawsuit &&
                    (!this.state.user_has_lawsuit.lawsuit.type || this.state.user_has_lawsuit.lawsuit.type !== 'lms') && (
                  <li>
                    <a
                      onClick={() => this.changeView("teacher", "live")}
                      className="link"
                    >
                      <p>
                        <img
                          width={24}
                          height={24}
                          className="icons-acreditation"
                          src={professores}
                        />
                        Professores
                      </p>
                    </a>
                  </li>
                    )}
                  {this.state.user_has_lawsuit &&
                    this.state.user_has_lawsuit.lawsuit.isSchedule === 1 && (
                      <li>
                        <a
                          onClick={() => this.changeView("schedule", "live")}
                          className="link"
                        >
                          <p>
                            <img
                              width={24}
                              height={24}
                              className="icons-acreditation"
                              src={programacao}
                            />
                            Programação
                          </p>
                        </a>
                      </li>
                    )}

                  {this.state.user_has_lawsuit &&
                    this.state.user_has_lawsuit.lawsuit.isMaterials === 1 && (
                      <li>
                        <a
                          onClick={() => this.changeView("assets", "live")}
                          className="link"
                        >
                          <p>
                            <img
                              width={24}
                              height={24}
                              className="icons-acreditation"
                              src={materiais}
                            />
                            Materiais
                          </p>
                        </a>
                      </li>
                    )}

                  {this.state.user_has_lawsuit &&
                    this.state.user_has_lawsuit.lawsuit.isLms === 1 &&
                    this.state.user_has_lawsuit.lawsuit.trail_id && (
                      <li className="">
                        <a
                          onClick={() => this.changeView("lms", "lms")}
                          className={
                            "link" +
                            (this.state.lmsShowSidebar_toggle
                              ? " link-open"
                              : "")
                          }
                        >
                          <div className="dropdown-pluz">
                            <p>
                              <img
                                width={24}
                                height={24}
                                className="icons-acreditation"
                                src={aulas}
                              />
                              Aulas
                            </p>
                            {!this.state.lmsShowSidebar_toggle && (
                              <i className="pe-7s-angle-down"></i>
                            )}
                            {this.state.lmsShowSidebar_toggle && (
                              <i className="pe-7s-angle-up"></i>
                            )}
                          </div>
                        </a>
                        {this.state.lmsShowSidebar_toggle &&
                          this.state.viewGroup === "lms" &&
                          this.state.user_has_lawsuit &&
                          this.state.user_has_lawsuit.lawsuit.trail_id && (
                            <div className="sliderlms">
                              <SliderLms
                                itemActive={this.state.itemActive}
                                handler={this.handler}
                                lmsCompletedCourse={this.completedCourse}
                                completedCourse={this.state.completedCourse}
                                setLms={this.setLms}
                                trail_id={
                                  this.state.user_has_lawsuit.lawsuit.trail_id
                                }
                              ></SliderLms>
                            </div>
                          )}
                      </li>
                    )}

                  {this.state.user_has_lawsuit &&
                    this.state.user_has_lawsuit.lawsuit.isLive !== 1 && (
                      <li>
                        <a
                          onClick={() => this.changeView("islive", "live")}
                          className="link"
                        >
                          <p>
                            <img
                              width={24}
                              height={24}
                              className="icons-acreditation"
                              src={aulaaovivo}
                            />
                            {this.state.user_has_lawsuit.lawsuit.type === "play"
                              ? "Chat"
                              : "Aula ao vivo"}
                          </p>
                        </a>
                      </li>
                    )}

                  {this.state.user_has_lawsuit &&
                    this.state.user_has_lawsuit.lawsuit.isForum === 1 && (
                      <li>
                        <a
                          onClick={() => this.changeView("forum", "live")}
                          className="link"
                        >
                          <p>
                            <img
                              width={24}
                              height={24}
                              className="icons-acreditation"
                              src={comentarios}
                            />
                            Comentários
                          </p>
                        </a>
                      </li>
                    )}

                  {this.state.user_has_lawsuit &&
                    this.state.user_has_lawsuit.lawsuit.isReplay === 1 && (
                      <li>
                        <a
                          onClick={() => this.changeView("video", "live")}
                          className="link"
                        >
                          <p>
                            <img
                              width={24}
                              height={24}
                              className="icons-acreditation"
                              src={replay}
                            />
                            Replays
                          </p>
                        </a>
                      </li>
                    )}

                  {this.state.user_has_lawsuit &&
                    this.state.user_has_lawsuit.lawsuit.isEvaluation === 1 && (
                      <li>
                        <a
                          onClick={() => this.changeView("form", "live")}
                          className="link"
                        >
                          <p>
                            <img
                              width={24}
                              height={24}
                              className="icons-acreditation"
                              src={avaliacao}
                            />
                            Avaliação
                          </p>
                        </a>
                      </li>
                    )}

                  {this.state.user_has_lawsuit &&
                    this.state.user_has_lawsuit.lawsuit.isVoting === 1 && (
                      <li>
                        <a
                          onClick={() => this.changeView("voting", "live")}
                          className="link"
                        >
                          <p>
                            <img
                              width={24}
                              height={24}
                              className="icons-acreditation"
                              src={avaliacao}
                            />
                            Votação
                          </p>
                        </a>
                      </li>
                    )}

                  {this.state.user_has_lawsuit &&
                    (!this.state.user_has_lawsuit.lawsuit.type || this.state.user_has_lawsuit.lawsuit.type !== 'lms') && (
                      <li>
                        <a
                          onClick={() => this.changeView("certificate", "live")}
                          className="link"
                        >
                          <p>
                            <img
                              width={24}
                              height={24}
                              className="icons-acreditation"
                              src={certificado}
                            />
                            Certificado
                          </p>
                        </a>
                      </li>
                    )}
                  <div className="text-center">
                    <a
                      className="btn btn-fill btn-info btn-zenite-text btn-zenite-text-round"
                      href="/#/home"
                    >
                      Meus Eventos
                    </a>
                  </div>
                  <a
                    href="/#/user"
                    className="zenite-p text-center my-4 justify-center d-flex align-center"
                  >
                    <img
                      width={24}
                      height={24}
                      className="icons-acreditation"
                      src={perfil}
                    />
                    Meu perfil
                  </a>
                  <a
                    href="/api/v1/account/logout"
                    className="zenite-p text-center my-2 justify-center d-flex align-center"
                  >
                    <img
                      height={19}
                      className="icons-acreditation"
                      src={sair}
                    />{" "}
                    Sair
                  </a>
                </ul>
              </div>
            </div>
          </div>
        )
        // END > !this.props.match.params.show_room
        }

        <div
          className={
            !this.props.match.params.show_room ? "page-acrediotation" : ""
          }
        >
          {!this.props.match.params.show_room && (
            <div
              className="top-banner-event"
              style={{ backgroundImage: "url(" + bannerEvent + ")" }}
            ></div>
          )}
          <div className="content-height">
            {content_page}
            {this.state.viewGroup === "lms" &&
              this.state.user_has_lawsuit &&
              this.state.user_has_lawsuit.lawsuit.trail_id && (
                <LmsPage
                  setLms={this.setLms}
                  handler={this.handler}
                  player={this.state.player}
                  lmsCompletedCourse={this.completedCourse}
                  itemActive={this.state.itemActive}
                  trailItemActive={this.state.trailItemActive}
                  trail_id={this.state.user_has_lawsuit.lawsuit.trail_id}
                />
              )}
          </div>
          <FooterZ />
        </div>
      </div>
    );
  }
}

export default Accreditation;
