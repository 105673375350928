import React, { Component } from "react";
import { Grid } from "react-bootstrap";
import logofooter from "../../assets/img/logo-footer.png";

class Footer extends Component {
  render() {
    return (
      <div className="footer-zenite">
        <div className="container hide-m justify-space-between footer-login-zenite d-flex align-center">
          <div className="text-center">
            <img src={logofooter} width={100} />
          </div>
          <div>
            <p> @ 2020-2023 Zênite. Todos os direitos reservados</p>
          </div>

          <div className="d-flex align-center justify-center">
            <div>
              {/* <img src={logofooter} width={20} /> */}
              <i className="pe-7s-call mr-1"></i>
            </div>
            <div className="flex-column-start">
              <p> Telefone: (41) 2109-8666</p>
              <p> WhatsApp: (41) 99643-4141</p>
            </div>
          </div>
          <div className="d-flex align-center justify-center">
            <div>
              {/* <img src={logofooter} width={20} /> */}
              <i className="pe-7s-call mr-1"></i>
            </div>
            <div className="flex-column-start">
              <p> Av. Sete de Setembro, 4698 - Batel</p>
              <p> Curitiba - PR - CEP: 80240-000</p>
            </div>
          </div>
        </div>
        <div className="container hide-d justify-space-between footer-login-zenite d-flex align-center">
          <div className="text-center">
            <img src={logofooter} width={100} />
          </div>
          <div className="my-2">
            <p> @ 2020-2023 Zênite. Todos os direitos reservados</p>
          </div>
          <div className="d-flex my-2">
            <div className="d-flex align-center justify-center">
              <div>
                {/* <img src={logofooter} width={20} /> */}
                <i className="pe-7s-call mr-1"></i>
              </div>
              <div className="flex-column-start">
                <p> Telefone: (41) 2109-8666</p>
                <p> WhatsApp: (41) 99643-4141</p>
              </div>
            </div>
            <div className="d-flex align-center justify-center">
              <div>
                {/* <img src={logofooter} width={20} /> */}
                <i className="pe-7s-call mr-1"></i>
              </div>
              <div className="flex-column-start">
                <p> Av. Sete de Setembro, 4698 - Batel</p>
                <p> Curitiba - PR - CEP: 80240-000</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
