import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";

import ReactMarkdown from "react-markdown";
import htmlParser from "react-markdown/plugins/html-parser";

import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import { Query } from "react-apollo";
import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";

import logo_zeniteonline from "../../assets/img/zenite-online.svg";
import logo_zeniteincompany from "../../assets/img/zenite-incompany.png";
import logo_zeniteplay from "../../assets/img/zenite-play.svg";
import logo_seminario from "../../assets/img/logo_6ene_1.png";


import logoConexao from "../../assets/img/logo_conexao01.png";

class PageContent extends Component {
  render() {
    const parseHtml = htmlParser({
      isValidNode: (node) => node.type !== "script",
      processingInstructions: [
        /* ... */
      ],
    });

    var logo = logo_zeniteonline;
    if (this.props.lawsuit.type === "in_company") {
      logo = logo_zeniteincompany;
    } else if (this.props.lawsuit.type === "play") {
      logo = logo_seminario;
    }

    // Logo para evento especifico
    if(this.props.lawsuit.id === 353) {
      logo = logoConexao;
    }

    return (
      <div className="page-content">
        {isMobile && (
          <React.Fragment>
            <div
              className="top-banner-event pb-1"
              style={{ backgroundImage: "url(" + this.props.bannerEvent + ")" }}
            >
              <h1
                name="about"
                className={
                  "titleTop titleTop_2 " +
                  (this.props.showBulkActions ? "" : "bannerActive")
                }
              >
                <div className="text-center">
                  <div className="py-3">
                  {
                      (this.props.lawsuit.type !== "play" || this.props.lawsuit.id === 353)   && (
                        <img className="logo-top-bar" src={logo} width="111" />
                      )
                    }
                  </div>
                </div>

                <div className="title-acreditation">
                  {this.props.lawsuit.title}
                  <br />
                  {this.props.lawsuit.presenter}
                </div>
                <hr class="z-hr"></hr>

                <div className="subtitle-acreditation">
                  {this.props.lawsuit.subtitle}
                </div>

                <div className="subtitle-acreditation">
                  {this.props.lawsuit.text_date}
                </div>

                {this.props.lawsuit.isLive !== 1 && (
                  <div className="h1_subtitle">{this.props.statusPage}</div>
                )}
              </h1>
            </div>
            {this.props.showAbout && (
              <div className="container">
                <div className="">
                  <h3>O Curso</h3>
                  <ReactMarkdown
                    source={this.props.lawsuit.description}
                    escapeHtml={false}
                    astPlugins={[parseHtml]}
                    linkTarget="_blank"
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        )}

        {!isMobile && (
          <React.Fragment>
            <div
              className="top-banner-event pb-1"
              style={{ backgroundImage: "url(" + this.props.bannerEvent + ")" }}
            >
              <Row>
                <Col md={6}>
                  <h1
                    name="about"
                    className={
                      "titleTop titleTop_2 " +
                      (this.props.showBulkActions ? "" : "bannerActive")
                    }
                  >
                    <div className="title-acreditation">
                      {this.props.lawsuit.presenter}
                      <br />
                      {this.props.lawsuit.title}
                    </div>
                    <hr class="z-hr"></hr>
                    <div className="subtitle-acreditation">
                      {this.props.lawsuit.subtitle}
                    </div>

                    <div className="subtitle-text-date">
                      {this.props.lawsuit.text_date}
                    </div>
                  </h1>
                </Col>
                <Col md={6} className="text-center">
                  <div className="py-2">
                    {
                      (this.props.lawsuit.type !== "play" ||  this.props.lawsuit.id === 353 ) && (
                        <img className="logo-top-bar" src={logo} width="111" />
                      )
                    }
                  </div>
                  {this.props.lawsuit.isLive !== 1 && (
                    <div className="h1_subtitle">{this.props.statusPage}</div>
                  )}
                </Col>
              </Row>
            </div>
            {this.props.showAbout && (
              <div className="container">
                <div className="">
                  <h3>O curso</h3>
                  <ReactMarkdown
                    source={this.props.lawsuit.description}
                    escapeHtml={false}
                    astPlugins={[parseHtml]}
                    linkTarget="_blank"
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default PageContent;
