import React, { Component, useState, useEffect } from 'react';
import Slider from '../../widgets/NetflixSlider';
import VideoYouTube from "../../components/Youtube/Youtube.jsx";
import { ExamEvaluative } from "../../components/Exam/ExamEvaluative.jsx";

import { HttpLink } from 'apollo-link-http';
import { ApolloClient } from 'apollo-client';
import { Exam } from "../../components/Exam/Exam.jsx";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { components } from 'react-select';

const restLink_lms = new HttpLink({
  uri: "/api/v1/lms/",
  credentials: 'include'
});
const lms_client = new ApolloClient({
  link: restLink_lms,
  cache: new InMemoryCache(),
});

const restLink_dam = new HttpLink({
  uri: "/api/v1/dam/",
  credentials: 'include'
});
const dam_client = new ApolloClient({
  link: restLink_dam,
  cache: new InMemoryCache(),
});

const _GET_TRAILITEMS = gql`
  query trailhascourse($trail_id: Int!){
    trailhascourse(trail_id:$trail_id) {
      trailhascourses{
        id
        course{
          id
          title
          description
          thumbnail
          scorm
          active
          video {
            id
            uuid
          }
        }
        
      }
    }
  }
`;

// const _GET_TRAILITEMS = gql`query getCourse($id: Int!) {
//   course(id:$id){
//     id,
//     title,
//     description,
//     scorm,
//     hours,
//     minutes,
//     thumbnail,
//     code,
//     video{
//       id, thumbnail
//     },
//     proof{
//       id, title
//     }, 
//     reactionevaluation{
//       id, title
//     }
//   }
// }
// `}

const GET_COURSE = gql`
  query course($id: Int!){
    course(id:$id){
      id,
      title,
      description,
      scorm,
      hours,
      minutes,
      thumbnail,
      code,
      video{
        id, thumbnail, uuid
      },
      proof{
        id, title
      }, 
      reactionevaluation{
        id, title
      }
    }
  }
`;

const VIDEO_HAS_USER = gql`
  mutation createOrUpdateVideoHasUser($video_id: String!, $time: Int!, $time_reproduced: Int!, $time_seconds: String!){
    createOrUpdateVideoHasUser( input:{video_id:$video_id, time:$time, time_reproduced:$time_reproduced, time_seconds:$time_seconds}) {
      id
    }
  }
`;

const TRAIL_ITEM_HAS_USER = gql`
  mutation trailItemHasUser($trail_id: Int!, $item_id: Int!, $item_type: String!){
      createTrailItemHasUser(input:{trail_id:$trail_id, item_id:$item_id, item_type:$item_type}){
      id
    }
  }
`;

const GET_TRAIL_ITEM_HAS_USER = gql`
  query getTrailItemHasUser($trail_id: Int!){
    trail_itens_by_user(trail_id: $trail_id) {
      trailitenshasuser{
        item_id, item_type
      }
    }
  }
`;

const GET_ASSET = gql`
  query asset($id: Int!){
    asset(id: $id) {
      id
      title
      asset
    }
  }
`;

const GET_TRAILITEMS = gql`
  query trailitems($trail_id: Int!){
    trailitems(trail_id:$trail_id) {
      trailitems{
        id, item_id, item_type, ordernum
      }
    }
  }
`;


const GET_PROOF = gql`
  query getProof($proof_id: Int!){
    proof(id: $proof_id) {
      questions {
        id
        title
        answers {
          id
          title
        }
      }
    }
  }`;

const GET_VIDEO_BY_USER = gql`
  query getVideoByUser($video_id: String!){
    getVideoByUser(video_id:$video_id) {
      time
      time_reproduced
      time_seconds
    }
  }
`;


const GET_TRAIL_PROOF = gql`
  query getTrail($trail_id: Int!){
    trail(id:$trail_id) {
      proof {
        id
        title
      }
    }
  }
`;



const GET_ANSWER_QUESTION = gql`
query answer_question_collections($proof_id: Int!, $item_id: Int!, $item_type: String!){
  answer_question_collections(proof_id: $proof_id, item_id: $item_id, item_type: $item_type){
    answers_question{
      id
    }
  }
}`;

const FINISH_TRAIL = gql`
  mutation addAnswerQuestion($trail_id: Int!, $status: String!){
    updateTrailHasUser(input:{trail_id:$trail_id, status:$status}) {
      id
    }
  }`;

const GET_TRAIL_REACTIONEVALUATION = gql`
  query getTrail($trail_id: Int!){
    trail(id:$trail_id) {
      reactionevaluation {
        id
        title
      }
    }
  }
`;

const GET_TRAIL = gql`
  query getTrail($id: Int!){
    trail(id:$id) {
      id
      title
      active
      blockcontent
    }
  }
`;

const GET_TRAIL_HAS_USER_ITEM = gql`
  query trail_has_user_item($trail_id: Int!){
    trail_has_user_item(trail_id: $trail_id){
      user_id
      status
    }
  }
`;

const GET_SETTINGS = gql`
  query get_settings{
    get_settings{
      id
      youtube_channel_id
      youtube_playlist_id
      youtube_playlist_title
      client_id
      client_secret
      token
      folder_id
      video_platform
    }
  }
  `;


var intervalGetCurlTime = null;

export default function SliderLms({ trail_id, itemActive, handler, setLms, completedCourse, lmsCompletedCourse }) {


  var time = 0;
  var arrSeconds = [];
  var timeAll = 0;
  var percVideo = [];
  var jsonObj = [];

  var completedCourseTrigger = [];

  const [trailSettingsState, setTrailSettingsState] = useState([]);

  const [trailItens, setTrailItens] = useState([]);



  const [thisTrail, setThisTrail] = useState([]);

  const [trailReactionevaluation, setTrailReactionevaluation] = useState([]);

  const [trailItemActive, setTrailItemActive] = useState(0);
  const [, setItemActive] = useState(0);
  // const [active, setActive] = useState(0);
  const [, setCompletedCourse] = useState([]);
  const [settingsPlatform, setSettingsPlatform] = useState([]);
  // const [aulas, setAulas] = useState([]);
  // const [perc, setPerc] = useState(false);

  const activeCourse = (trailItemActive, itemActive) => {

    
    handler(false);
    setTrailItemActive(trailItemActive);
    setItemActive(itemActive);
    setLms(trailItemActive, itemActive);



    if (trailItemActive.item_type === "course" && itemActive.video === null) {
      completedCourseTrigger[trailItemActive.ordernum] = true;
      setCompletedCourse(completedCourseTrigger);
      lmsCompletedCourse(completedCourseTrigger);
      lms_client.mutate({
        variables: { trail_id: trail_id, item_id: trailItemActive.item_id, item_type: 'course' },
        mutation: TRAIL_ITEM_HAS_USER
      });
    }

  }




  async function init() {

    if (!trail_id) {
      return null;
    }
    var TrailSettings = (await lms_client.mutate({
      variables: { id: trail_id },
      mutation: GET_TRAIL
    })).data;
    if (!TrailSettings) {
      return null;
    }
    var thisTrail = (await lms_client.query({
      variables: { trail_id: trail_id },
      query: GET_TRAIL_PROOF
    })).data.trail;
    if (!thisTrail) {
      return null;
    }



    setTrailSettingsState(TrailSettings.trail);



    var finishTrail = (await lms_client.mutate({
      variables: { trail_id: trail_id, status: "started" },
      mutation: FINISH_TRAIL
    })).data;



    // lms_client.mutate({
    //   mutation: GET_TRAILITEMS,
    //   variables: { trail_id: trail_id, status: "started" },
    // });

    var trailItens = (await lms_client.query({
      variables: { trail_id: trail_id },
      query: GET_TRAILITEMS
    })).data.trailitems.trailitems;


    var trailItens = (await lms_client.query({
      variables: { trail_id: trail_id },
      query: GET_TRAILITEMS
    })).data.trailitems.trailitems;


    var trailReactionevaluation = (await lms_client.query({
      variables: { trail_id: trail_id },
      query: GET_TRAIL_REACTIONEVALUATION
    })).data.trail;

    var trailReactionevaluation = (await lms_client.query({
      variables: { trail_id: trail_id },
      query: GET_TRAIL_REACTIONEVALUATION
    })).data.trail;

    var settings = (await lms_client.query({
      query: GET_SETTINGS
    })).data;

    setSettingsPlatform(settings.get_settings.video_platform);


    // var proofTrail = (await lms_client.query({
    //   variables: { proof_id: 2 },
    //   query: GET_PROOF
    // })).data.proof.questions;

    var trailFinish = (await lms_client.query({
      variables: { trail_id: trail_id },
      query: GET_TRAIL_HAS_USER_ITEM
    })).data.trail_has_user_item;


    if (typeof thisTrail.proof !== "undefined" && thisTrail.proof !== null) {
      if (trailItens[trailItens.length - 1].__typename !== "Proof" && trailItens[trailItens.length - 2].__typename !== "Proof" && trailItens[trailItens.length - 3].__typename !== "Proof") {
        trailItens.push({
          id: thisTrail.proof.id,
          item_id: thisTrail.proof.id,
          item_type: 'Proof',
          ordernum: trailItens.length + 1,
          __typename: "Proof",
        });
      }
    }

    if (trailFinish !== null && trailFinish !== undefined) {
      if (trailFinish.status === "finalized") {
        if (trailItens[trailItens.length - 1].__typename === "Proof") {
          completedCourseTrigger[trailItens.length] = true;
          setCompletedCourse(completedCourseTrigger);
          lmsCompletedCourse(completedCourseTrigger);
        } else {
          completedCourseTrigger[trailItens.length - 2] = true;
          setCompletedCourse(completedCourseTrigger);
          lmsCompletedCourse(completedCourseTrigger);
        }
      }
    }

    if (trailReactionevaluation.reactionevaluation !== null && trailReactionevaluation.reactionevaluation !== undefined) {
      if (trailItens[trailItens.length - 1].__typename !== "Reactionevaluation" && trailItens[trailItens.length - 2].__typename !== "Reactionevaluation" && trailItens[trailItens.length - 3].__typename !== "Reactionevaluation") {
        trailItens.push({
          id: trailReactionevaluation.reactionevaluation.id,
          item_id: trailReactionevaluation.reactionevaluation.id,
          item_type: 'Reactionevaluation',
          ordernum: trailItens.length + 1,
          __typename: "Reactionevaluation",
        });
      }
    }

    if (thisTrail.proof !== null && thisTrail.proof !== undefined) {

      if (trailItens[trailItens.length - 1].__typename !== "Certificate" && trailItens[trailItens.length - 2].__typename !== "Certificate" && trailItens[trailItens.length - 3].__typename !== "Certificate") {
        trailItens.push({
          id: 100,
          item_id: 100,
          item_type: 'Certificate',
          title: 'Certificado',
          ordernum: trailItens.length + 1,
          __typename: "Certificate",
        });
      }

    }

    var answerQuestionTrail = (await lms_client.query({
      variables: { proof_id: trailItens[trailItens.length - 1].id, item_id: trailItens[trailItens.length - 1].item_id, item_type: "trail" },
      query: GET_ANSWER_QUESTION
    })).data.answer_question_collections;



    if (answerQuestionTrail && answerQuestionTrail.answers_question.length) {

      completedCourseTrigger[trailItens.length] = true;
      setCompletedCourse(completedCourseTrigger);
      lmsCompletedCourse(completedCourseTrigger);
    }

    var trailItensCompleted = (await lms_client.query({
      variables: { trail_id: trail_id },
      query: GET_TRAIL_ITEM_HAS_USER,
      fetchPolicy: 'no-cache'
    })).data.trail_itens_by_user.trailitenshasuser;

    trailItensCompleted.forEach(function (trailItemCompleted, trailItemCompletedIndex) {
      trailItens.forEach(function (trailItem, trailItemIndex) {
        if (trailItemCompleted.item_id === trailItem.item_id && trailItemCompleted.item_type === trailItem.item_type) {

          completedCourseTrigger[trailItem.ordernum] = true;
          setCompletedCourse(completedCourseTrigger);
          lmsCompletedCourse(completedCourseTrigger);
        }
      });
    });

    // Seta todos os itens da trilha
    setTrailItens(trailItens);


    setTrailReactionevaluation(trailReactionevaluation.reactionevaluation);

    setThisTrail(thisTrail.proof);

    // Seta o primeiro item da trilha como ativado, depois verifica se ele curso ou asset para pegar o conteudo e setar como ativado
    var trailItem = trailItens[0];

    if (trailItem.item_type == 'course') {
      var itemCourse = (await lms_client.query({
        variables: { id: trailItem.item_id },
        query: GET_COURSE
      })).data.course;
      
      var videoId = false;
      if (itemCourse.video !== null && itemCourse.video.uuid !== null) {
        var videoId = itemCourse.video.uuid;
      }

      var videoByUser = (await lms_client.query({
        variables: { video_id: videoId },
        query: GET_VIDEO_BY_USER
      })).data.getVideoByUser;

      if (videoByUser !== null){
        itemCourse.video.videoTime = videoByUser.time_reproduced; 
      }else{
        itemCourse.video.videoTime = 0;
      }



      


      activeCourse(trailItem, itemCourse);
    } else if (trailItem.item_type == 'asset') {
      await dam_client.query({
        variables: { id: trailItem.item_id },
        query: GET_ASSET
      }).then(
        result => {
          activeCourse(trailItem, result.data.asset)
        });
    }
  }

  function uniqArrayValues(a) {
    var seen = {};
    var out = [];
    var len = a.length;
    var j = 0;
    for (var i = 0; i < len; i++) {
      var item = a[i];
      if (seen[item] !== 1) {
        seen[item] = 1;
        out[j++] = item;
      }
    }
    return out;
  }

  useEffect(() => {
    init();
  }, []);


  let [, setState] = useState();

  function handleUpdate() {
    //passing empty object will re-render the component

    setState({});
  }




  return (
    <div className="course-page">
      <div className="app">
        <Slider>
          {trailItens.map((trailItem, index) => (

            <div key={index}>

              { trailItem.item_type === 'course' &&
                <Query
                  client={lms_client}
                  query={GET_COURSE}
                  variables={{ id: trailItem.item_id }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <div>Carregando...</div>;
                    if (error) return <div>Erro :(</div>;

                    if (!data.course) {
                      return (<div></div>);
                    }
                    
                    var videoId = false;
                    if (data.course.video !== null && data.course.video.uuid !== null) {
                      var videoId = data.course.video.uuid;
                    }

                    if (trailSettingsState.blockcontent === 1) {
                      var unlock = false;
                    } else {
                      var unlock = true;
                    }

                    

                    if (trailItem.ordernum === 1 || typeof completedCourse[trailItem.ordernum] !== "undefined" || typeof completedCourse[trailItem.ordernum - 1] !== "undefined") {
                      unlock = true;
                    }

                    var courseDate = data;

                    
                    
                    return (
                      <Query
                        client={lms_client}
                        query={GET_VIDEO_BY_USER}
                        variables={{ video_id: videoId }}
                      >
                        {({ loading, error, data }) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;
                            
                            if (data.getVideoByUser !== null){
                              courseDate.course.video.videoTime = data.getVideoByUser.time_reproduced; 
                            }else{
                              courseDate.course.video.videoTime = 0;
                            }
                          return (
                            <div>
                              <button disabled={!unlock} key={completedCourse} className="btn-course" onClick={() => activeCourse(trailItem, courseDate.course)}>
                                <Slider.Item unlock={unlock} completedCourse={completedCourse[trailItem.ordernum]} movie={courseDate.course} active={itemActive} key={courseDate.course}></Slider.Item>
                              </button>
                            </div>
                          );
                        }}
                      </Query>
                    );
                  }}
                </Query>
              }

              { trailItem.item_type === 'asset' &&

                <Query
                  client={dam_client}
                  query={GET_ASSET}
                  variables={{ id: trailItem.item_id }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <div>Carregando...</div>;
                    if (error) return <div>Erro :(</div>;

                    if (!data.asset) {
                      return (<div></div>);
                    }
                    if (trailSettingsState.blockcontent === 1) {
                      var unlock = false;
                    } else {
                      var unlock = true;
                    }

                    if (trailItem.ordernum === 1 || typeof completedCourse[trailItem.ordernum] !== "undefined" || typeof completedCourse[trailItem.ordernum - 1] !== "undefined") {
                      unlock = true;
                    }
                    return (
                      <div>
                        <button disabled={!unlock} key={completedCourse} className="btn-course" onClick={() => activeCourse(trailItem, data.asset)}>
                          <Slider.Item unlock={unlock} completedCourse={completedCourse[trailItem.ordernum]} movie={data.asset} active={itemActive} key={data.asset.id}></Slider.Item>
                        </button>
                      </div>
                    );
                  }}
                </Query>
              }
              { trailItem.item_type === 'Proof' &&
                <Query
                  client={lms_client}
                  query={GET_TRAIL_PROOF}
                  variables={{ trail_id: trail_id }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <div>Carregando...</div>;
                    if (error) return <div>Erro :(</div>;

                    if (!data.trail) {
                      return (<div></div>);
                    }

                    if (trailSettingsState.blockcontent === 1) {
                      var unlock = false;
                    } else {
                      var unlock = true;
                    }

                    if (trailItem.ordernum === 1 || typeof completedCourse[trailItem.ordernum] !== "undefined" || typeof completedCourse[trailItem.ordernum - 1] !== "undefined") {
                      unlock = true;
                    }
                    return (
                      <div>
                        <button disabled={!unlock} key={completedCourse} className="btn-course" onClick={() => activeCourse(trailItem, data.trail.proof)}>
                          <Slider.Item unlock={unlock} completedCourse={completedCourse[trailItem.ordernum]} movie={data.trail.proof} active={itemActive} key={data.trail.proof}></Slider.Item>
                        </button>
                      </div>
                    );
                  }}
                </Query>
              }
              { trailItem.item_type === 'Certificate' &&
                <Query
                  client={lms_client}
                  query={GET_TRAIL_PROOF}
                  variables={{ trail_id: trail_id }}
                >
                  {({ loading, error, data }) => {

                    if (trailSettingsState.blockcontent === 1) {
                      var unlock = false;
                    } else {
                      var unlock = true;
                    }

                    if (trailItens[trailItens.length - 2].item_type === "Reactionevaluation") {
                      if (trailItem.ordernum === 1 || typeof completedCourse[trailItem.ordernum] !== "undefined" || typeof completedCourse[trailItem.ordernum - 2] !== "undefined") {
                        unlock = true;
                      }
                    } else {
                      if (trailItem.ordernum === 1 || typeof completedCourse[trailItem.ordernum] !== "undefined" || typeof completedCourse[trailItem.ordernum - 1] !== "undefined") {
                        unlock = true;
                      }
                    }



                    return (
                      <div>
                        <button disabled={!unlock} key={completedCourse} className="btn-course" onClick={() => activeCourse(trailItem, trailItem)}>
                          <Slider.Item unlock={unlock} completedCourse={completedCourse[trailItem.ordernum]} movie={trailItem} active={itemActive} key={trailItem}></Slider.Item>
                        </button>
                      </div>
                    );
                  }}
                </Query>
              }
              { trailItem.item_type === 'Reactionevaluation' &&
                <Query
                  client={lms_client}
                  query={GET_TRAIL_REACTIONEVALUATION}
                  variables={{ trail_id: 2 }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <div>Carregando...</div>;
                    if (error) return <div>Erro :(</div>;

                    if (!data.trail) {
                      return (<div></div>);
                    }

                    if (trailSettingsState.blockcontent === 1) {
                      var unlock = false;
                    } else {
                      var unlock = true;
                    }

                    if (trailItem.ordernum === 1 || typeof completedCourse[trailItem.ordernum] !== "undefined" || typeof completedCourse[trailItem.ordernum - 1] !== "undefined") {
                      unlock = true;
                    }

                    return (
                      <div>
                        <button disabled={!unlock} key={completedCourse} className="btn-course" onClick={() => activeCourse(trailItem, data.trail.reactionevaluation)}>
                          <Slider.Item unlock={unlock} completedCourse={completedCourse[trailItem.ordernum]} movie={data.trail.reactionevaluation} active={itemActive} key={data.trail.reactionevaluation}></Slider.Item>
                        </button>
                      </div>
                    );
                  }}
                </Query>
              }

            </div>
          ))}
        </Slider>
      </div>

    </div>
  );

}